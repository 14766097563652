import logo from './assets/images/logo.svg';
import grahamIcon from './assets/images/graham-icon.svg';
import maggieIcon from './assets/images/maggie-icon.svg';
import tellerIcon from './assets/images/teller-icon.svg';
import woodyIcon from './assets/images/woody-icon.svg';
import './App.scss';

function App() {
  return (
    <div className="container">
      <div className="header-wrap">
        <div className="header-bg"></div>
      </div>
      <div className="page-bg"></div>
      <img src={logo} className="logo" alt="hair of the dog logo" />
      <section className="menu">
        
        <p className='about-me centered'>Welcome to Hair of the Dog! The most prestigious non sex dungeon basement on all of Borland Rd. Sit down, relax and let's get you a drink. Don't forget to <strong>raise the hooch for the pooch!</strong><br/><br/>Cheers!</p>

        <h2>Original Cocktails</h2>

        <article className="menu-item">
          <img src={grahamIcon} className="icon" alt="dog icon" />
          <h3>The Grambleweed</h3>
          <p>Similar to <strong>Graham</strong> in color, rather sweet but a bit spicy at times. This drink just wants to play&nbsp;ball.</p>
          <p className="ingredients">Tres Generaciones Tequila, Illegal Mezcal, Reyas Ancho Chili Liqueur, Hot Pepper Bitters, Fresh Lemon, Fresh Ginger, Ginger&nbsp;Syrup</p>
        </article>


        <article className="menu-item">
          <img src={maggieIcon} className="icon" alt="dog icon" />
          <h3>Miss Maggie May</h3>
          <p>Similar to <strong>Maggie</strong> in color, rather dark but 
              with streaks of white. This drink can be
              something to enjoy while relaxing or 
              while hyped&nbsp;up.
          </p>
          <p className="ingredients">Plantation Rum, Maggies Farm Coffee Liqueur, Sassafras and Sorghum Bitters, Demerara Syrup, Heavy&nbsp;Cream</p>
        </article>

        <article className="menu-item">
          <img src={woodyIcon} className="icon" alt="dog icon" />
          <h3>Coral Root</h3>
          <p>It's just root beer. Filled to the brim with cream, covered in nuts, virgin. Just like the man&nbsp;himself.</p>
          <p className="ingredients">Non-Mug Root Beer, Cold Brew Coffee, Sassafras and Sorghum Bitters, Demerara Syrup, Heavy&nbsp;Cream,&nbsp;Nutmeg</p>
        </article>

        <article className="menu-item">
          <img src={tellerIcon} className="icon" alt="dog icon" />
          <h3>A Tiller The Rum</h3>
          <p>Not at all similar to <strong>Tiller</strong>, this drink has coconut.
            I've have never seen him eat, nor wear a coconut.
            This drink is a lie (but its a rather tasty&nbsp;tiki).
          </p>
          <p className="ingredients">Homemade Banana Liqueur, Plantation Rum, Pineapple Juice, Cream of Coconut</p>
        </article>

        <article className="menu-item">
          <img src={tellerIcon} className="icon" alt="dog icon" />
          <h3>The Lily Pad</h3>
          <p>Just like <strong>Lily</strong>, this drink is a bit sour at first 
          but sweetens up as you get to know&nbsp;it.</p>
          <p className="ingredients">Quantum Vodka, Lemoncello, Fresh Lemon, Strawberry&nbsp;Syrup</p>
        </article>

        <article className="menu-item">
          <img src={tellerIcon} className="icon" alt="dog icon" />
          <h3>The Hiya Maya</h3>
          <p>I got nothing. Hiya is short for hibiscus, and <strong>Maya</strong> is the dog. It rhymed.
            The fuck you want from me?
          </p>
          <p className="ingredients">Barr Hill Gin, Hibiscus syrup, Fresh Strawberries, Fresh Lime, Casamigos mezcal</p>
        </article>

        <article className="menu-item">
          <img src={tellerIcon} className="icon" alt="dog icon" />
          <h3>Bowie's In Space</h3>
          <p>Does the space cold make your nipples pointy?
          </p>
          <p className="ingredients">Bourbon, Fernet Branca, Maraschino Liqueur, Demerara&nbsp;Syrup</p>
        </article>

        <article className="menu-item">
          <img src={tellerIcon} className="icon" alt="dog icon" />
          <h3>Your Shadow</h3>
          <p>Always watching, always waiting, always your Shadow.</p>
          <p className="ingredients">Black Tea infused Vodka, Crème de Violette, Fresh Lemon, Honey Syrup, Squid&nbsp;Ink</p>
        </article>

        <p className='centered'>...More to come once they can sit and stay...</p>

        <section className="other-cocktails">
          <h2>Cucktails</h2>
          <h3>The Sweet Girl</h3>
          <h3>Fruit Salad</h3>
          <h3>Gregg Nog</h3>
        </section>

        <section className="other-cocktails">
          <h2>Modern Cocktails</h2>
          <h3>Penicillin</h3>
          <h3>Industry Sour</h3>
        </section>

        <section className="other-cocktails">
          <h2>C&nbsp;&nbsp;&nbsp;lassic Cocktails</h2>
          <h3>Old Fashioned</h3>
          <h3>In Fashion (IWC)</h3>
          <h3>Whisky Sour</h3>
          <h3>Gold Rush</h3>
          <h3>Sazerac</h3>
          <h3>Margarita</h3>
          <h3>Bramble</h3>
          <h3>Bees Knees</h3>
          <h3>Bee Sting</h3>
          <h3>Tom Collins</h3>
          <h3>Negroni</h3>
          <h3>Pegu Club</h3>
          <h3>The Last Word</h3>
          <h3>Daiquiri</h3>
          <h3>Queens Park Swizzle</h3>
          <h3>Pisco Sour</h3>
        </section>


      </section>
    </div>
  );
}

export default App;
